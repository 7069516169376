.prodisplayf{
    display: flex;
}
.prowebimg{
width: 100%;
border-radius: 15px;
}
.proimgdiv{
width: 50%;
}
.colll{
    color:orange; font-size: 20px;
}
.pp{
    margin-left: 10px; color: black;
}
.protext{
    text-align: justify;
}

@media only screen and (max-width: 600px) {
    .prodisplayf{
        display: contents;
    }
    .proimgdiv{
        width: 100%;
        }
}