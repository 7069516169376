.w1{
    max-width: 400px;
}
.ss{
    font-size: smaller;
}
.blu{
    color: blue;
}
.hh{
    margin-right: 10px; color: black; font-size: 20px;
}
.btttt{
    margin-top: 15px;
           outline: 0;
           border: 0;
           cursor: pointer;
           will-change: box-shadow,transform;
           box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgb(58 65 111 / 50%);
           border-radius: 6px;
           color: orange;
           height: fit-content;
           width: 100%;
           text-align:justify;
           font-size: 15px;
           text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
           transition: box-shadow 0.15s ease,transform 0.15s ease;
           :hover {
               box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #3c4fe0;
               transform: translateY(-2px);
           }
           :active{
               box-shadow: inset 0px 3px 7px #3c4fe0;
               transform: translateY(2px);
}}
