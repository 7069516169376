.ppq{
    display: flex;
}
.colll{
    color:orange; font-size: 20px;
}
.pp{
    margin-left: 10px; color: black;
}
.ppqq{
    text-align: center;  border-radius: 20px; margin-top: 10px; width: 100%;  background-color: transparent;
}
.domaintext{
text-align: justify;
}
.domdispflex{
    display: flex;
}
.hhqq{
    background-color: orange; border-radius: 10px;
    color: #ffffff;
}
.ookdd{
    width: 400px; border-radius: 10px; margin-left: 10px;
}
.serv{
    background-color: orange; border-radius: 10px;
    color: #ffffff;
}
.ssss{
    background-color: orange; border-radius: 10px; margin-bottom: 10px;
    color: #ffffff;
}
.proimgw{
    width: 50%;
}
@media only screen and (max-width: 600px) {
    .domdispflex{
        display: contents;
    }
    
.proimgw{
    width: 100%;
}

}