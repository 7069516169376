.colll{
    color:orange; font-size: 20px;
}
.harddispflex{
    display: flex;
}
.hardimgdov{
    width: 50%;
}
.pp{
    margin-left: 10px; color: black;
}
.hardtextjus{
    text-align: justify;
}
.ppqq{
    text-align: center;  border-radius: 20px; margin-top: 10px; width: 100%;  background-color: transparent;
}
@media only screen and (max-width: 600px) {
    .harddispflex{
        display: contents;
    }
    .hardimgdov{
        width: 100%;
    }
}