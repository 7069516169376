.b11{
   width: 100%;
   border-radius: 40px;
   margin-left: auto;
   margin-right: auto;
   }
   .webimgdiv{
width: 50%;
   }
  .webtextjus{
   text-align: justify;
  } 
   .webdisf{
      display: flex;
   }
   .ddddf{
      display: flex;
   }
   .df{
  display: flex;
   }
   .p112{
    margin-left: 10px; color: black;
   }
 
   .szfont{
      color:orange; font-size: 20px;
   }
   @media only screen and (max-width: 600px) {
      .webdisf{
         display: contents;
      } 
      .webimgdiv{
         width: 100%;
            }
   }