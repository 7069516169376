.m20{
    margin: 20px;
}
.ckb{
    font-size:18px;color: rgb(210, 116, 0);
}
.fsm{
    font-size: smaller;
}
.CCTdisplayf{
display: flex;
}
.cctvdisf{
display: flex;
}
.ing1{
    border-radius: 15px;
}
.ccttextjus{
text-align: justify;
}
.shootharddiv{
width: 50%;
}
@media only screen and (max-width: 600px) {
    .CCTdisplayf{
        display: contents;
    }
    .shootharddiv{
        width: 100%;
        }
}