.immmr{
   
    width: 450px;
    -webkit-filter: drop-shadow(10px 10px 10px #ffffff);
    filter: drop-shadow(5px 5px 5px #ffffff);
    animation: myfirst 3s 1000;
    animation-direction: alternate;
    position: relative;
 }
 @keyframes myfirst {
    0%   { bottom: 0px; top: 0px;}
    50%  { bottom: 0px; top: 20px;}
    100%   { bottom: 0px; top: 0px;}
  }
  .mrkdispflex{
    display: flex;
}
.marktextdiv{
width: 100%;
}
.markdiv{
  width: 50%;
}
  .pp{
    margin-left: 10px; color: black;
}
.colll{
    color:orange; font-size: 20px;
}
@media only screen and (max-width: 600px) {
  .mrkdispflex{
      display: contents;
  }
  .immmr{
    width: 100%;
    margin-bottom: 15px;
  }.markdiv{
    width: 100%;
  }

}