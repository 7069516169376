.sootdisfle{
    display: flex;
}
.colll{
    color:orange; font-size: 20px;
}
.pp{
    margin-left: 10px; color: black;
}
.mlp{
    margin-left: 10px;
}
.immmr22{
   
    width: 100%;
    height: auto;
    border-radius: 10px;
 }
 .shootdivimg{
    width: 50%;
 }
 .shhottextjus{
    text-align: justify;
 }
 @media only screen and (max-width: 600px) {
    .sootdisfle{
        display: contents;
    }
    .shootdivimg{
        width: 100%;
     }
}