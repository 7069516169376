.mob1{
    display: flex;
}
.mx-w{
    max-width: 400px;
}
.f-s{
    font-size: smaller;
    text-align: justify;
}
.ccor{
    color: orange; text-align: center;
}
.servicesbox{
    border: 3px solid rgb(58, 133, 186); 
    text-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255); 
    width: 400px;
    margin: 5px;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 2px rgb(154, 192, 220);
}

.servicesbox:hover{
    cursor: pointer;
    transition: 0.4s;
    border-color: orange;
    transition: 0.4s;
    transform: scaleX(1.01);

}
.a1:hover{
    color: #000000;
    font-weight: bold;
    transform: scale(2.2);
}
.iconn{
    font-size:45px;
    color: rgb(210, 116, 0);
}
.a1{
    text-decoration: none;
}
.hea5{
    color: black;
}

@media only screen and (max-width: 600px) {
    .mob1{
        display: contents;
    }   
    .servicesbox{
        border: 3px solid rgb(58, 133, 186); 
        text-align: center;
        align-items: center;
        background-color: rgb(255, 255, 255); 
        width: 100%;
        margin: 5px;
        border-radius: 5px;
        box-shadow: 2px 2px 2px 2px rgb(154, 192, 220);
    }
}