
.d-ff{
    display: flex;
}
.abutextjus{
    text-align: justify;
}
.aboutimg{
width: 100%;
margin-top: -50px;
}
.textsix{
font-size: 22px;
color: black;
}
@media only screen and (max-width: 600px) {

    .aboutimg{
        width: 100%;
        margin-top: 0px;
        }
        .d-ff{
            display: contents;
        }
        .h3e{
            margin-bottom: 60px;
        }

}