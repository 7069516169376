.mobdisplayflex{
    display: flex;
}
.mobimgdiv{
width: 50%;
}
.mobimgst{
    width: 100%;
    border-radius: 40px;
    margin-left: auto;
    margin-right: auto;
}
.mobtextjust{
    text-align: justify;
}
.colll{
    color:orange; font-size: 20px;
}
.pp{
    margin-left: 10px; color: black;
}
@media only screen and (max-width: 600px) {

    .mobdisplayflex{
        display: contents;
    }
    .mobimgdiv{
        width: 100%;
        }

}