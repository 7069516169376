
.www{
    display: flex;
 }
 .boodom{
    text-align: justify;
 }
 .bootextdib {
    width: 100%;
 }
.bookformdiv{
    width: 50%;
}
     .formGroup {
         margin: 10px;
     }
     @media only screen and (max-width: 600px){
        .www{
            display: contents;
         }
         .bootextdib {
            width: 100%;
         }
        .bookformdiv{
            width: 100%;
        } 
     }
     textarea {
         resize: vertical;
     }
.ppp{
    color: #000000;font-size: 19px ;font-weight: bold;
}
