.suptext{
    color: #000000;font-size: 19px ;font-weight: bold;
}
.heading1 {
    text-align: center;
    margin-bottom: 10px;
}

.heading1 h2 {
    color: #007bff;
}

.formGroup {
    margin-bottom: 5px;
    color: black;
    font-weight: bold;
    font-size: smaller;
}
input,
select,
textarea {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: smaller;
   }

select {
    appearance: none;
    background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 24,0 12,24"/></svg>') no-repeat;
    background-position: right 12px top 50%, 0 0;
    background-size: 10px auto, 100%;
    cursor: pointer;
    background-color: #ffffff;
}
.suptextdiv{
width: 50%;

}
.supformdiv{
width: 50%;
}
textarea {
    resize: vertical;
}
.www{
   display: flex;
}
.pppl{
color: #000000;font-size: 19px ;font-weight: bold;
}
.p112{
text-align: justify;
}
@media only screen and (max-width: 600px)  {
   .www{
       display: contents;
   }
   .col-6 {
       width: 100%;
   }
   .suptextdiv{
    width: 100%;
    
    }
    .supformdiv{
    width: 100%;
    }
}
