.mon1{
    display: flex;
}
.imgwirt{
width: 100%;
}
.gggg{
    width: 50%;
}
.p11{
    text-align: justify;
    margin-top: 45px;
    width: 100%;
    line-height: 2.1;
}
.disf{
display: flex;
}
.ffj{
    font-size:20px;
    color: rgb(210, 116, 0);
}
.ml10{
    margin-left: 10px;
}
.demo1 {
    /* background: url(../img/sliders/bacimage.jpg) center center no-repeat; */
    background-attachment: fixed;
    background-size: cover;
    border-radius: 10px;
    margin-top: 50px; border-radius: 15px; width: 100%; height: fit-content; background-color: orange;
}
.phh1{
  margin-right: 10px; color: black; font-size: 20px;
}
.fontsm{
    font-size: small;
}
.vierr{
    border-radius: 15px;
     width:100%;
      height:250px;
    }
    .houswidiv{
width: 50%;
    }
@media only screen and (max-width: 600px) {
    .mon1{
display: contents;
    }
    .vierr{
        border-radius: 15px;
         width:100%;
          height:250px;
        }
        .houswidiv{
            width: 100%;
                }
                .imgwirt{
                    width: 100%;
                    }

    }