.bgcolor{
    background-color: rgb(58, 133, 186);
}
.disf{
    display: flex;
}
.ref{
    margin-left: 23px; text-align:left;
}
.bgorg{
    background-color :orange;
}
.discon{
display: contents;
}
.btn{
    text-decoration: none;
    color: white;
}
.btn:hover{
    color: orange;
}