.logocss{
    width: 55px;
}
.quadilog{
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: rgb(88, 155, 208);
    letter-spacing:0.3vw;
}
.nav-link.active{
    font-weight: bold;
     
}
.quadilog2{
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: orange;
    letter-spacing:0.3vw;
}