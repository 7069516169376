
.mob1{
    display: flex;   
}
.ourco{
    height: 200px;
    width: 200px;
}
.maindive{
    height: 200px;
    width: 210px;
    margin-top: 50px;
}
.maindive1{
    height: 200px;
    width: 210px;
}
.ourco:hover{
    cursor: pointer;
    color: white;
    transition: 0.4s;
    transform: scaleY(1.1);
    transform: scaleX(1.1);

}
@media only screen and (max-width: 600px) {
    .mob1{
        display: contents;
    }
    .maindive1{
        height: 200px;
        width: 210px;
        margin-top: 50px;
    }   
   
}