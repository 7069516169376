.minh{
    min-height: 100%
}
.ps{
    color: #000000;font-size: 19px ;font-weight: bold;
}
.ps2{
    color: #000000;font-size: 18px ;font-weight: bold;
}
.mapp{
    min-height: 300px; border:0;
}
.soicn{
    color: green; font-size: 30px; padding: 10px;
}
.soiw{
    padding: 10px;color: orange; font-size: 30px;  
}
.soip{
    padding: 10px;color: blue; font-size: 30px;
}