.main-style{
    margin-top: 60px; 
    width: 100%; height: fit-content;

}
.demo1 {
    background: url(./bacimage.jpg) center center no-repeat;
    background-attachment: fixed;
    background-size: cover;
    border-radius: 10px;
}
.bttnn{
    display: inline-block;
       outline: 0;
       border: 0;
       cursor: pointer;
       will-change: box-shadow,transform;
       background: radial-gradient( 100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100% );
       box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgb(58 65 111 / 50%);
       padding: 0 32px;
       border-radius: 6px;
       text-decoration: none;
       color: #fff;
       height:fit-content;
       font-size: 18px;
       text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
       transition: box-shadow 0.15s ease,transform 0.15s ease;
       :hover {
           box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgba(255, 230, 0, 0.3), inset 0px -3px 0px #3c4fe0;
           transform: translateY(-2px);
           
       }
       :active{
           box-shadow: inset 0px 3px 7px #3c4fe0;
           transform: translateY(2px);
}}