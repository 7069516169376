.icon-bar {
    position: fixed;
    top: 40%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .icon-bar .a1 {
    display: block;
    text-align: center;
    padding: 5px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
  }
  

  .whatsapp{
    background: green;
    color: white;
  }
  .Instragram{
    background: rgb(240, 42, 42);
    color: white;
  }
  .facebook {
    background: #3B5998;
    color: white;
  }
  
  .twitter {
    background: #55ACEE;
    color: white;
  }
  
  .google {
    background: #dd4b39;
    color: white;
  }
  
  .linkedin {
    background: #007bb5;
    color: white;
  }
  
  .youtube {
    background: #bb0000;
    color: white;
  }
