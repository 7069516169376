.Softdisf{
    display: flex;
}
.softtextjus{
    text-align: justify;
}
.imgdiv{
width: 50%;
}

.soft_img{
    width: 80%; 
}

.colll{
    color:orange; font-size: 20px;
}
.pp{
    margin-left: 10px; color: black;
}
.mm1{
    text-align: center; border-color:orange; border-style: solid; border-radius: 20px; margin-top: 10px; width: 100%;  background-color: transparent;
}
.colror{
    color: orange
}
@media only screen and (max-width: 600px) {
    .Softdisf{
        display: contents;
    }
    .imgdiv{
        width: 100%;
        }
        .soft_img{
            width: 100%; 
        }
}